//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import BenefitsBoomerang from '../../components/marketing/benefitsBoomerang'
import TemplateCalloutLink from '../../components/elements/templateCalloutLink'

const useCaseData = [
  {
    title: 'Find and retain loyal gym members',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      'Now more than ever, your gym needs to find and retain loyal members. Combine visual social marketing with integrated content creation to keep your brand top-of-mind',
    descriptionColor: 'white-3',
    image: 'usecaseAthleticClub',
    iconShow: true,
    iconContext: 'fitness',
    // iconColor: 'sky',
    // lineClass: 'green-1',
    // bgcolor: 'dkgreen-8',
    // border: 'border-top-2-dkgreen-1',
    bggradient: 'tropical',
    expandcol: 5,
    buttonText: 'See gym templates',
    buttonPath: '/tag/gym',
    buttonClass: 'btn-cta-outline',
  },
]

const industryExamples = [
  {
    title: 'Offers and promotions',
    titlecolor: 'sky-1',
    desccolor: 'black-1',
    image: 'exampleGym1',
    imageShadow: true,
    imageBorderColor: 'white-1',
    lineClass: 'sky-5',
    iconShow: true,
    iconContext: 'tag',
    iconFill: 'white',
    iconColor: 'sky',
    description:
      'Drive repeat visits and new members by creating image-rich offers and promotions for your customers',
  },
  {
    title: 'Amenities and programs',
    titlecolor: 'bluegreen-1',
    desccolor: 'black-1',
    image: 'exampleGym2',
    imageShadow: true,
    imageBorderColor: 'white-1',
    lineClass: 'bluegreen-4',
    iconShow: true,
    iconContext: 'heartpulse',
    iconColor: 'bluegreen',
    description:
      'Differentiate your club with highlighted amenities and drive attendance to your programs',
  },
  {
    title: 'Classes and instructors',
    titlecolor: 'blue-1',
    desccolor: 'black-1',
    image: 'exampleGym3',
    imageShadow: true,
    imageBorderColor: 'white-1',
    lineClass: 'blue-4',
    iconShow: true,
    iconContext: 'people',
    iconFill: 'white',
    iconColor: 'blue',
    description:
      'Encourage attendance at key classes, or highlight featured instructors by using templates that showcase your offerings',
  },
  {
    title: 'Lifestyle content',
    titlecolor: 'green-1',
    desccolor: 'black-1',
    image: 'exampleGym4',
    imageShadow: true,
    imageBorderColor: 'white-1',
    // lineClass: 'green-4',
    iconShow: true,
    iconContext: 'lightbulb',
    iconColor: 'green',
    description:
      'Engage your customers with visual lifestyle content and tips & tricks - all easily customized via templates',
  },
]

const featureData = {
  title: 'Keep your gym top-of-mind with customers',
  titlecolor: 'black-4',
  mode: 'triovertical',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Offers and promotions',
          titlecolor: 'sky-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'sky-1',
          iconname: 'tag',
          iconcolor: 'sky',
          path: '/platform/offers-promotions/',
          description:
            'Drive repeat visits and new members by creating image-rich offers and promotions for your customers',
        },
        {
          title: 'Amenities and programs',
          titlecolor: 'bluegreen-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'bluegreen-1',
          iconname: 'megaphone',
          iconcolor: 'bluegreen',
          // path: '/platform/review-management/',
          description:
            'Drive attendance to your programs and differentiate your club with highlighted amenities',
        },
        {
          title: 'Lifestyle content',
          titlecolor: 'green-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'green-1',
          iconname: 'lightbulb',
          iconcolor: 'green',
          path: '/platform/pulse-surveys/',
          description:
            'Engage your customers with visual lifestyle content and tips & tricks - all easily customized via templates',
        },
        {
          title: 'Classes and instructors',
          titlecolor: 'blue-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'blue-1',
          iconname: 'lightbulb',
          iconcolor: 'blue',
          // path:'',
          description:
            'Encourage attendance at key classes, or highlight featured instructors by using templates that showcase your offerings',
        },
      ],
    },
  ],
}

const templateCallout = {
  noun: 'gym',
  adjective: 'gym',
  path: '/tag/gym',
  description:
    'See gym, fitness center & athletic club templates from our community',
}
const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Fitness Clubs - Visual Content Marketing"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how Storylava can help you drive more revenue and member engagement at your gym / fitness club',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium blue-1">
            Drive gym revenue with visual social content
          </h1>
          <p class="display-4">
            Effectively communicate with prospects &amp; members about new
            promotions, classes, and amenities — and achieve better loyalty and
            ROI
          </p>
        </div>
      </div>
    </div>

    <AlternatingCallout featureData={useCaseData} />

    {/* SELECTED FEATURES */}
    {/* <FeatureQuad featureData={featureData} /> */}
    <AlternatingCallout shift={true} featureData={industryExamples} />
    {/* END SELECTED FEATURES */}

    <TemplateCalloutLink data={templateCallout} />
  </Layout>
)

export default About
